@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  background-color: #0aff0a4d;
}

.button{
  cursor: pointer;
  display: block;
  min-width: 200px;
  margin: 10px auto;
  text-align: center;
  border: 2px solid green;
  border-radius: 5px;
  background: rgb(100,255,100);
  box-shadow: 0px 5px 2px green;
  transition: 0.25s ease-out;   
  color: black;
}

.button:hover{
  background: rgb(150,255,150);
  color: black;
}

.Header{
  background: linear-gradient(#00EE00 75%, rgb(185,255,185));
}

.Header > h1{
  font-family: 'Libre Baskerville', serif;
  font-size: 3em;
  font-weight: bold;
}

.Header > nav{
  font-family: 'Libre Baskerville', serif;
  font-weight: bold;
  font-size: 2em;
}

.button:active{
 background: rgb(50,255,50);
 box-shadow: 0px 2.5px 2px green;
 transform: translateY(2.5px);
} 

.col-form-label{
  font-size: larger;
}

#streetTableContainer{
  border: 2px #009900 solid;
  border-radius: 10px;
}

.table {
  border: 2px black;
  border-radius: 5px;
}

@media screen and (max-width: 500px){
  .Header > h1{
    font-family: 'Libre Baskerville', serif;
    font-size: 2em;
    font-weight: bold;
  }
  
  .Header > nav{
    font-family: 'Libre Baskerville', serif;
    font-weight: bold;
    font-size: 1.5em;
  }
}
