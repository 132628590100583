.App {
  text-align: center;
}

.navbar-toggler{
  background-color: white;
}

.App-link {
  color: #61dafb;
}

.hidden {
  display: none;
}

.nav-link {
  cursor: pointer;
  background-image: transparent;
}

.nav-link:hover{
  background-image: radial-gradient(ellipse, rgb(185,255,185) 30%, rgba(117, 55, 55, 0) 70%);
}
